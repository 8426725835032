.frame {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:50px;
    height:50px;
    margin:auto auto;
    -webkit-animation: spin 6s linear infinite;
    animation: spin 6s linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.innerCircle {
    position:absolute;
    background:white;
    top:0;
    left:0px;
    right:0;
    bottom:0;
    margin:auto;
    width:80px;
    height:80px;
    border-radius:100%;
    z-index:1;
}
.circle {
    position:absolute;
    background:rgb(239, 132, 3);
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    width:100px;
    height:100px;
    border-radius:100%;
}

.bar {
    position:absolute;
/*        background:black;*/
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    width:10px;
    height:200px;
    z-index:10;
}

.bar:before {
    content:'';
    position:absolute;
    background:rgb(239, 132, 3);
    width:10px;
    height:30px;
}

.bar:nth-child(1) {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}
.bar:nth-child(2) {
    -webkit-transform:rotate(80deg);
    transform: rotate(80deg);
    }
.bar:nth-child(3) {
    -webkit-transform:rotate(120deg);
    transform: rotate(120deg);
}
.bar:nth-child(4) {
    -webkit-transform:rotate(160deg);
    transform: rotate(160deg);
}
.bar:nth-child(5) {
    -webkit-transform:rotate(200deg);
    transform: rotate(200deg);
}
.bar:nth-child(6) {
    -webkit-transform:rotate(240deg);
    transform: rotate(240deg);
}
.bar:nth-child(7) {
    -webkit-transform:rotate(280deg);
    transform: rotate(280deg);
}
.bar:nth-child(8) {
    -webkit-transform:rotate(320deg);
    transform: rotate(320deg);
}
.bar:nth-child(9) {
    -webkit-transform:rotate(360deg);
    transform: rotate(360deg);
}