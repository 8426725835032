.card {
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
}

.outerWrapper {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.icon {
    width: 150px;
}

.cardColumn {
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}