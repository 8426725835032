.outerWrapper {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: rgb(244, 246, 248);
}

.paymentMethodActive {
  border: 5px solid var(--secondary-color);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.paymentMethodPassive {
  border: 1px solid #a7a3a3;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.bankSelected {
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.circleDiv {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 20px 0;
  background-color: white;
  place-content: center;
  align-items: center;
  place-items: center;
  display: flex;
}

@media screen and (max-width: 991px) {
  .bankSelected {
    flex-direction: column;
  }
}