@import '../../index.css';

.copyrightWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 30px;
    font-size: 14px;
    background-color: var(--primary-color);
    color: 'white'
}

.copyrightWrapperAkbank{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: fit-content;
    font-size: 12px;
    background-color: var(--primary-color);
    color: 'white'
}

@media screen and (max-width: 991px) {
    .copyrightWrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: 30px;
        font-size: 10px;
        color: 'white'
    }

    .copyrightWrapperAkbank{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        height: fit-content;
        font-size: 10px;
        color: 'white'
    }
}