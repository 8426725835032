@import '../../index.css';

.mainWrapper {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    tab-size: 4;
    --reach-skip-nav: 1;
    font-family: var(--font-fam);
    font-weight: 400;
    line-height: 1.5;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    background-color: var(--theme-ui-colors-cloud-1,#F7F7F7);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.solarVisPopper {
    position: fixed;
    right: 20px;
    background-color: white;
    bottom: 200px;
    border-radius: 10px;
    max-width: 600px;
    width: 100%;
    padding: 30px;
    padding-top: 10px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.solarVisPopperMinimize {
    position: fixed;
    right: 20px;
    background-color: transparent;
    bottom: 120px;
    max-width: 200px;
    width: 100%;
    padding: 10;  
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solarVisPopperMinimizeTextDiv {
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.popperExpandIcon {
    width: 70px;
}

.nextButton {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--primary-color);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    align-self: flex-start;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
    margin-top: 20px;
}

.startText {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.buttonIcon {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-left: 1rem;
}

.circleDiv {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 40px 0;
    background-color: white;
    place-content: center;
    align-items: center;
    place-items: center;
    display: flex;
}

.HomeButton {
    background-color: white;
    border-radius: 30px;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    outline: none;
    border: none;
    font-size: 20px;
    background-color: #b2e791;
    width: 200px
}

.HomeButton:hover {
    background-color: rgb(233, 190, 80)
}

.HomeButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.outerWrapper {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-template-columns: 0 repeat(12,[start] 1fr) [end] 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    align-content: center;
    grid-template-rows: 100%;
    grid-gap: 2rem;
    padding-bottom: 0.5rem;
}

.wrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    align-self: center;
    height: 80vh;
    padding: 0rem;
    min-height: 600px;
    grid-column: 3 / span 10;
}

.solarCalculator {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    position: relative;
    z-index: 0;
    height: 100%;
}

.anotherWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
}

.motionDiv {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    position: absolute;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
    opacity: 1;
}

.stepWrapper {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 1.125rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
}

.stepWrapperUnsetHeight {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 1.125rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
}

.stepWrapper::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.stepWrapperLastQuoteSent {
    box-sizing: border-box;
    margin: 0;
    display: flex;
    height: 100%;
    justify-content: center;
}

.stepWrapperLast {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 1.125rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
}

.stepper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    grid-column: 1 / 3;
    height: 4.6875rem;
    z-index: 1;
    box-shadow: 0 2px 7px rgba(0,0,0,.2);
    display: flex;
}

.leftWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    overflow: hidden;
    display: flex;
    max-height: none;
}

.rightWrapper {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
    background-color: var(--theme-ui-colors-bliss,#FFFFFF);
    grid-template-rows: 1fr;
    overflow-y: auto;
    overflow-x: hidden;
    grid-row-gap: 0rem;
}

.headerOne {
    margin: 0;
    min-width: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    font-size: 0.75rem;
    top: -0.5rem;
    left: -0.5rem;
    width: 1px;
    height: 1px;
}

.leftStepper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
    background-color: white;
    align-items: center;
    display: grid;
    padding: 0 4vw;
    grid-template-columns: auto 1fr auto;
    grid-gap: 1rem;
}

.rightStepper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    background-color: var(--primary-color);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
}

.rightStepperAnimated {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    background-color: var(--primary-color);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    /* animation-name: animate-pop;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-duration: 0.5s; */
    animation: animate-grow 3s normal forwards ease-in-out;
    animation-iteration-count: 1;
}

.stepperButton {
    min-width: 0px;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    font-weight: 500;
    cursor: pointer;
    /* pointer-events: none; */
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms;
    height: 100%;
    width: 20%;
    border-radius: 0;
    background-color: var(--theme-ui-colors-bliss,#FFFFFF);
    opacity: 1;
}

.stepperButton:disabled {
    cursor: not-allowed;
}

.buttonTextUnderline {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    text-transform: none;
    font-weight: 500;
    cursor: pointer;
    pointer-events: none;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    white-space: break-spaces;
    position: relative;
}

.buttonTextUnderline::before {
    position: absolute;
    display: block;
    content: " ";
    height: 2px;
    border-radius: 1rem;
    width: 100%;
    background-color: var(--primary-color);
    bottom: -0.75rem;
    z-index: 2;
}

.buttonText {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    text-transform: none;
    font-weight: 500;
    cursor: pointer;
    pointer-events: none;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    white-space: break-spaces;
    position: relative;
}

.costSavingWrapper {
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.totalCost {
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 1.5rem;
}

.costSaving {
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
}

.costSavingText {
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
}

.costSavingText2 {
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 0.75rem;
    font-weight: 500;
}

@media screen and (max-width: 991px) {
    .mainWrapper {
        min-height: 100vh;
    }

    .solarVisPopper {
        right: 0px;
    }

    .solarVisPopperMinimize {
        right: 0px;
    }

    .solarVisPopperMinimizeTextDiv {
        font-size: 0.8rem;
    }

    .popperExpandIcon {
        width: 40px;
    }

    .outerWrapper {
        grid-gap: 0rem;
        padding-bottom: 0rem;

    }

    .wrapper {
        grid-column: start / end;
        align-self: center;
        height: 100%;
        min-height: unset;
        padding: 0rem;
    }

    .solarCalculator {
        margin: 0px;
        min-width: 0px;
        position: relative;
        z-index: 0;
        height: 100%;
    }

    .anotherWrapper {
        margin: 0px;
        min-width: 0px;
        height: 100%;
    }

    .motionDiv {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        position: absolute;
        top: 0rem;
        right: 0rem;
        bottom: 0rem;
        left: 0rem;
        opacity: 1;
    }

    .stepWrapper {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: grid;
        grid-gap: 0rem;
        position: relative;
        grid-template-columns: 1fr;
        /* grid-template-rows: auto 1fr 2fr; */
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 0;
    }

    .stepWrapperUnsetHeight {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: grid;
        grid-gap: 0rem;
        position: relative;
        grid-template-columns: 1fr;
        /* grid-template-rows: auto 1fr 2fr; */
        height: unset;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 0;
    }

    .stepWrapperLast {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: grid;
        grid-gap: 0rem;
        position: relative;
        grid-template-columns: 1fr;
        grid-template-rows: none;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 0;
    }

    .stepper {
        margin: 0px;
        min-width: 0px;
        display: none;
        grid-column: 1 / 3;
        height: 4.6875rem;
        z-index: 1;
        box-shadow: 0 2px 7px rgba(0,0,0,.2);
    }

  }

@media screen and (max-width: 1200px) and (orientation: landscape) {
    .wrapper {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        align-self: center;
        height: 80vh;
        padding: 0rem;
        min-height: 600px;
        grid-column: 2 / span 12;
    }
}

@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
    }

@keyframes animate-grow {
0% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

50% {
    -webkit-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
}

100% {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}
}