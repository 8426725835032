.circleDiv {
    border-radius: 50%;
    /*  width: 200px;
    height: 200px; */
    margin: 40px 0;
    background-color: #4f8650;
    place-content: center;
    align-items: center;
    place-items: center;
    display: flex;
}

.Button {
    border-radius: 30px;
    color: white;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    outline: none;
    border: none;
    font-size: 20px;
    background-color: #373242;
    width: 200px
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}