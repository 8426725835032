@import '../../index.css';

.ShowInfoContainerDraw {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 22vw;
    height: 10vw;
    padding: 20px;
    z-index: 1;
    left: 100px;
    top: 500px;
    background-color:  #6fe8a4;
    color: #103e4c;
    font-size: 20px;
    border-radius: 30px;
}

.directionTextRegion {
    position: absolute;
    align-items: center;
    justify-content: center;
    justify-items: center;
    z-index: 1;
    font-size: 20px;
    width: 50%;
    border-radius: 30px;
    text-align: center;
    padding: 0px 30px;
}

.directionText {
    padding: 10px;
    font-size: 1rem;
    background-color: white;
    color: black;
    border-radius: 10px;
}

.SearchBoxDiv {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 10px 20px;
    z-index: 1;
    left: 50%;
    top: 30%;
    background-color:  white;
    color: #103e4c;
    font-size: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    transform: translate(-50%, -50%);
}

.SearchBoxDivMobile {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 60px;
    /* padding: 10px 20px; */
    z-index: 1;
    left: 50%;
    top: 30%;
    background-color:  white;
    color: #103e4c;
    font-size: 16px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    transform: translate(-50%, -50%);
}

.RoofAreaInput {
    place-items: center;
    margin: auto;
    display: flex;
    text-align: center;
    background-color: whitesmoke;
    border-radius: 30px;
    padding: 5px 20px;
    outline: none;
    border: none;
    font-size: 18px;
    border: 1px solid #fff;
    width: 200px;
  }

.RoofAreaInputMobile {
    place-items: center;
    margin: auto;
    display: flex;
    text-align: center;
    background-color: whitesmoke;
    border-radius: 30px;
    padding: 5px 20px;
    outline: none;
    border: none;
    font-size: 20px;
    border: 1px solid #fff;
    width: 200px;
}

.NextButtonDiv {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 0;
    z-index: 1;
    left: 700px;
    top: 600px;
    background-color:  white;
    color: #103e4c;
    font-size: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
}

.NextButtonMobileDiv {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 0;
    z-index: 1;
    left: 50%;
    top: 88%;
    color: #103e4c;
    font-size: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    transform: translate(-50%, -50%);
}

.MobileInfoParag {
    font-size: 18px;
    margin-bottom: 5px;
    text-align: center;
}

.MobileAreaInput {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 330px;
    height: fit-content;
    padding: 6px 20px;
    z-index: 1;
    left: 50%;
    top: 84%;
    background-color:  hsl(211, 10%, 90%);
    color: #103e4c;
    font-size: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    transform: translate(-50%, -50%);
}

.ShowInfoContainer {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: calc(230px + 8vw);
    height: fit-content;
    padding: 20px;
    z-index: 1;
    left: 60px;
    top: 190px;
    background-color:  hsl(211, 10%, 90%);
    color: #103e4c;
    font-size: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
}

.ShowInfoContainerMobile {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: fit-content;
    padding: 20px;
    z-index: 1;
    left: 40px;
    top: 100px;
    background-color:  hsl(211, 10%, 90%);
    color: #103e4c;
    font-size: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
}


.Button {
    background-color: whitesmoke;
    border-radius: 30px;
    color: #103e4c;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 5px;
    font-weight: bold;
    outline: none;
    border: none;
    width: 130px;
}

.ButtonNextMobile {
    background-color: whitesmoke;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    font: inherit;
    padding: 3px;
    margin: 5px;
    outline: none;
    border: none;
    width: 130px;
}

.ButtonMobile {
    background-color: whitesmoke;
    border-radius: 30px;
    color: #103e4c;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    outline: none;
    border: none;
    width: 130px;
}

.Button:hover {
    background-color: #e8b559;
}

.Button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.gifWrapper {
    height: 200px;
    width: 300px;
}

.gifWrapperMobile {
    height: 180px;
    width: 270px;
}

.availableArea {
    place-content: center;
    place-items: center;
    margin-top: 10px;
}

.startDrawButton {
    position: absolute;
    align-items: center;
    justify-content: center;
    z-index: 1;
    left: 90px;
    top: 420px;
    background-color: whitesmoke;
    border-radius: 30px;
    color: #103e4c;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    outline: none;
    border: none;
    font-size: 22px;
}

.availableAreaMobile {
    place-content: center;
    place-items: center;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 330px;
    height: fit-content;
    padding: 20px;
    z-index: 1;
    left: 20px;
    top: 400px;
    background-color:  hsl(211, 10%, 90%);
    color: #103e4c;
    font-size: 20px;
    border-radius: 30px;
}

.desktopInfoBoxWrapper {
    color: white;
    margin: 0px;
    min-width: 0px;
    position: absolute;
    bottom: 0rem;
    width: 50%;
    transform: translate(0px, 0px);
    opacity: 1;
    visibility: inherit;
}

.desktopInfoBox {
    color: white;
    visibility: inherit;
    box-sizing: border-box;
    min-width: 0px;
    display: grid;
    gap: 0rem;
    background-color: var(--primary-color);
    z-index: 99;
    position: absolute;
    left: 0rem;
    right: 0rem;
    margin: auto;
    padding: 1.5rem 1rem;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    bottom: 1rem;
    width: 80%;
    border-radius: 3px;
}

.infoBoxText {
    color: white;
    visibility: inherit;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 1.4rem;
}

.resetText {
    visibility: inherit;
    color: currentcolor;
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
    box-sizing: border-box;
    margin: 0px 0px 0px 0.5rem;
    min-width: 0px;
    display: inline;
    font-size: 1.2rem;
}

.drawAgainButton {
    visibility: inherit;
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    position: relative;
    z-index: 0;
    display: flex;
    appearance: none;
    border-top-width: initial;
    border-right-width: initial;
    border-bottom-width: initial;
    border-style: none none none solid;
    border-image: initial;
    box-shadow: none;
    color: currentcolor;
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background: none;
    padding: 0rem;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
    text-decoration-color: transparent;
    border-left-width: 1px;
    border-color: var(--theme-ui-colors-concrete,#C4C4C4);
}

.mobileInfoBoxWrapper {
    color: var(--theme-ui-colors-void,#373242);
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    /* position: absolute; */
    bottom: 0rem;
    width: 100%;
    transform: translate(0px, 0px);
    opacity: 1;
    visibility: inherit;
}

.mobileInfoBox {
    visibility: inherit;
    box-sizing: border-box;
    min-width: 0px;
    display: grid;
    gap: 0rem;
    grid-template-columns: 1fr 1fr;
    background-color: var(--theme-ui-colors-background,#FFFFFF);
    z-index: 99;
    position: absolute;
    bottom: 0rem;
    border-radius: 0px;
    left: 0rem;
    right: 0rem;
    margin: auto;
    padding: 0.75rem 1rem;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
}