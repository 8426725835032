.card {
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    min-height: 380px;
}

.outerWrapper {
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: rgb(244, 246, 248);
}

.icon {
    width: 150px;
}

.cardColumn {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    min-height: fit-content;
}

h2 {
    font-size: 1.4rem; 
}

.allh2 {
    font-size: 1.4rem; 
    font-weight: normal;
}

@media screen and (max-width: 991px) {
    .card {
        padding: 15px;
        margin-top: 0;
    }

    .cardColumn {
        padding: 15px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .allh2 {
        font-size: 1.2rem; 
    }
}