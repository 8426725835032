.rooflabelActive {
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    background-color: var(--primary-color);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    cursor: pointer;
    transition: all 150ms ease 0s;
    font-size: 1rem;
    height: 80px;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.75rem 1rem;
    width: 45%;
    margin-right: 5%;
    margin-bottom: 5px;
}

.roofLabel {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    background-color: var(--theme-ui-colors-cloud-1,#F7F7F7);
    cursor: pointer;
    transition: all 150ms ease 0s;
    font-size: 1rem;
    height: 80px;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.75rem 1rem;
    width: 45%;
    margin-right: 5%;
    margin-bottom: 5px;
}

.roofDiv {
    color: inherit;
    cursor: pointer;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-left: 0.75rem;
    flex-shrink: 0;
}

.spanRoofLabel {
    color: inherit;
    cursor: pointer;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.radioRoof {
    min-width: 0px;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    max-width: 100%;
    border: 1px solid;
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0px;
}

.extraIcon {
    color: inherit;
    cursor: pointer;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    align-items: center;
}

.checkbox {
    min-width: 0px;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    max-width: 100%;
    border: 1px solid;
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0px;
}

@media screen and (max-width: 991px) {
    .battery {
        font-size: 0.875rem;
    }
}