@import '../../index.css';

.leftWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    overflow: hidden;
    max-height: none;
}

.rightWrapper {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
    background-color: var(--theme-ui-colors-bliss,#FFFFFF);
    grid-template-rows: 1fr;
    overflow-y: auto;
    overflow-x: hidden;
    grid-row-gap: 0rem;
}

.map {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
    background: url("/harita_ss.PNG") center / cover;
}

.rightWrapperlv1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    grid-column: start / end;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    padding: 0rem;
}

.rightWrapperlv2 {
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 2rem;
}

.title {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-bottom: 0.25rem;
}

.formwizard {
    margin: 0px;
    min-width: 0px;
    grid-column: 1 / -1;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 4px;
    bottom: 0rem;
    margin-top: auto;
    position: sticky;
    background-color: white;
    padding: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.formwizardlv1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding: 1rem 0rem;
}

.buttonContainer {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.nextButton {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--primary-color);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    align-self: flex-start;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
    margin-top: 0rem;
    margin-left: 1rem;
}

.nextButton:disabled {
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    background-color: var(--theme-ui-colors-gray-2,#BABABA);
    cursor: not-allowed;
}

.backButton {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--theme-ui-colors-gray-2,#BABABA);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
}

.backButton:disabled {
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    background-color: var(--theme-ui-colors-gray-2,#BABABA);
    cursor: not-allowed;
}

.startText {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.buttonIcon {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-left: 1rem;
}

.backButtonIcon {
    text-transform: none;
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    font-family: "Beausite Classic";
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    transform: scaleX(-1);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    margin-right: 1rem;
}

.inputAdressWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
}

.titleh2 {
    margin: 0;
    min-width: 0;
    line-height: 1.35;
    letter-spacing: normal;
    font-weight: 500;
    border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    font-size: 1.5rem;
    padding-bottom: 0rem;
    margin-bottom: 0.75rem;
    border-bottom: none;
}

.roofAreaInput {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 1rem;
    padding-left: 0.5rem;
    width: 100%;
}

.roofAreaInput:focus {
    outline: none;
}

.roofAreaInput:disabled {
    background-color: transparent;
}

.inputBox {
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid;
    padding: 0.5rem 0.5rem;
    border-radius: 99999px;
    min-width: 0px;
    font-family: inherit;
    margin: 0;
    width: 30%;
    max-width: 100%;
    line-height: 1.15;
    font-size: 1rem;
    min-height: 3rem;
    margin-left: 2.5rem;
}

.inputBoxDisabled {
    display: flex;
    align-items: center;
    background-color: #e6e4e4;
    border: 1px solid;
    padding: 0.5rem 0.5rem;
    border-radius: 99999px;
    min-width: 0px;
    font-family: inherit;
    margin: 0;
    width: 30%;
    max-width: 100%;
    line-height: 1.15;
    font-size: 1rem;
    min-height: 3rem;
    margin-left: 2.5rem;
}

.prefix {
    font-weight: 300;
    color: #999;
}

@media screen and (max-width: 991px) {
    .leftWrapper {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: block;
        max-height: 38vh;
        overflow: hidden;
    }

    .rightWrapper {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: grid;
        grid-gap: 0rem;
        grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
        background-color: var(--theme-ui-colors-bliss,#FFFFFF);
        grid-template-rows: 1fr;
        grid-row-gap: 0rem;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rightWrapperlv1 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        grid-column: start / end;
        display: flex;
        flex-direction: column;
        margin-top: 0.2rem;
        margin-bottom: 6rem;
        padding: 0rem;
        
    }

    .rightWrapperlv2 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: flex;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -webkit-box-pack: start;
        justify-content: flex-start;
        padding: 1rem;
    }

    .title {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        margin-bottom: 0.75rem;
    }

    .inputAdressWrapper {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
    }

    .titleh2 {
        font-size: 1.25rem;
        line-height: 1.35;
        letter-spacing: normal;
        font-weight: 500;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid;
        border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    }

    .nextButtonDeactive {
        border-radius: 99999px;
        padding: 1rem 1.5rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
        margin-top: 1rem;
        margin-left: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        align-self: flex-start;
    }

    .nextButtonActive {
        border-radius: 99999px;
        padding: 1rem 1.5rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
        margin-top: 1rem;
        margin-left: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        align-self: flex-start;
    }

    .startText {
        line-height: 1.15;
        font-size: 0.875rem;
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
    }
    
    .buttonIcon {
        line-height: 1.15;
        font-size: 0.875rem;
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        margin-left: 1rem;
    }

    .adresGir {
        font-size: 0.875rem;
    }

    .spanText {
        font-size: 0.875rem;
        font-weight: 400;
    }

    .formwizard {
        color: var(--theme-ui-colors-void,#4e4e50);
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        grid-column: 1 / -1;
        position: absolute;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 4px;
        bottom: 0rem;
        margin-top: auto;
        padding: 0rem;
    }

    .formwizardlv1 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        padding: 0.5rem 1rem;
    }

    .backButton {
        border-radius: 99999px;
        padding: 1rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
    }

    .nextButton {
        border-radius: 99999px;
        padding: 1rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
    }

    .backButtonIcon {
        font-size: 0;
        margin-right: 0rem;
    }

    .buttonIcon {
        font-size: 0;
        margin-left: 0rem;
    }

    .startText {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: none;
    }

    .inputBox {
        width: 40%;
        margin-left: 0;
    }
}