.card {
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
}

.outerWrapper {
    width: 100%;
    height: 90vh;
    min-height: 100%;
}

.icon {
    width: 150px;
}

.cardColumn {
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

h2 {
    font-size: 1.8rem; 
}

.allh2 {
    font-size: 1.8rem; 
    font-weight: normal;
}