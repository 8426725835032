@import '../../index.css';

.leftWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    overflow: hidden;
    display: flex;
    max-height: none;
}

.rightWrapper {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
    background-color: var(--theme-ui-colors-bliss,#FFFFFF);
    grid-template-rows: 1fr;
    overflow-y: auto;
    overflow-x: hidden;
    grid-row-gap: 0rem;
}

.costSavingWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.costSaving {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    margin-left: 0.4rem;
}

.costSavingText {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
    font-weight: bold;
}

.costSavingText2 {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 0.75rem;
    font-weight: 500;
    font-weight: bold;
}


.mapHome {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
    background: url("/home_photo.jpg") center / cover;
}
.mapFactory {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
    background: url("/factory_photo.jpg") center / cover;
}

.mapCommercial {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
    background: url("/commercial_photo.jpg") center / cover;
}

.mapTarimsal {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
    background: url("/tarimsal_sulama.jpg") center / cover;
}

.rightWrapperlv1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    grid-column: start / end;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    padding: 0rem;
}

.rightWrapperlv2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 2rem;
}

.title {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-bottom: 0.6rem;
}

.title2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
}

.titleh2 {
    margin: 0;
    min-width: 0;
    line-height: 1.35;
    letter-spacing: normal;
    font-weight: 500;
    border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    font-size: 1.5rem;
    padding-bottom: 0rem;
    margin-bottom: 0.75rem;
    border-bottom: none;
}


.formwizard {
    margin: 0px;
    min-width: 0px;
    grid-column: 1 / -1;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 4px;
    bottom: 0rem;
    margin-top: auto;
    position: sticky;
    background-color: white;
    padding: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.formwizardlv1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding: 1rem 0rem;
}

.buttonContainer {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.nextButton {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--primary-color);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    align-self: flex-start;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
    margin-top: 0rem;
    margin-left: 1rem;
}

.nextButton:disabled{
    background-color: var(--theme-ui-colors-gray-2,#BABABA);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
}

.backButton {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--theme-ui-colors-gray-2,#BABABA);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
}

.startText {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.buttonIcon {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-left: 1rem;
}

.backButtonIcon {
    text-transform: none;
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    font-family: "Beausite Classic";
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    transform: scaleX(-1);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    margin-right: 1rem;
}

.inputAdressWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
}

.radioWrapper {
    box-sizing: border-box;
    margin: 0px 0px 0.75rem;
    min-width: 0px;
    display: grid;
    gap: 1rem;
    margin-bottom: 1.125rem;
}

.facilityChoice {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;
    gap: 0.75rem;
    width: 100%;
    grid-auto-flow: column;
    grid-template-columns: unset;
}

.rooflabelActive {
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--primary-color);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    cursor: pointer;
    transition: all 150ms ease 0s;
    font-size: 1rem;
    height: 48px;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.75rem 1rem;
}

.roofLabel {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--theme-ui-colors-cloud-1,#F7F7F7);
    cursor: pointer;
    transition: all 150ms ease 0s;
    font-size: 1rem;
    height: 48px;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.75rem 1rem;
}

.roofDiv {
    color: inherit;
    cursor: pointer;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-left: 0.75rem;
    flex-shrink: 0;
}

.spanRoofLabel {
    color: inherit;
    cursor: pointer;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.radioRoof {
    min-width: 0px;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    max-width: 100%;
    border: 1px solid;
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0px;
}

.optionsWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;
    gap: 0.75rem;
    -webkit-box-align: center;
    align-items: center;
    grid-auto-flow: column;
    margin-top: 1rem;

}

.checkWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.extraLabelActive {
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--primary-color);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    cursor: pointer;
    transition: all 150ms ease 0s;
    gap: 0.5rem;
    width: fit-content;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.75rem 1rem;
    margin-right: 0.75rem;
}

.extraLabelDeactive {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--theme-ui-colors-cloud-1,#F7F7F7);
    cursor: pointer;
    transition: all 150ms ease 0s;
    gap: 0.5rem;
    width: fit-content;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.75rem 1rem;
    margin-right: 0.75rem;
}

.extraIcon {
    color: inherit;
    cursor: pointer;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
}

.extraTexth3 {
    margin: 0px;
    min-width: 0px;
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
}

.checkbox {
    min-width: 0px;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    max-width: 100%;
    border: 1px solid;
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0px;
}

.multipleChoiceBlock {
    color: #112f48;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    -webkit-box-direction: normal;
    visibility: visible;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
    position: relative;
}

.multipleChoiceBlockInner {
    color: #112f48;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    -webkit-box-direction: normal;
    visibility: visible;
    box-sizing: border-box;
    padding: 0;
    border: 0;
    overflow: initial;
    margin: 0 auto;
    /* width: 1260px; */
}

.multipleChoiceBlockInnerInner {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    -webkit-box-direction: normal;
    visibility: visible;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    position: relative;
    align-items: normal;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: -20px;
    margin-right: -20px;
}

.multipleChoiceNumbers {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    -webkit-box-direction: normal;
    visibility: visible;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    width: 920px;
}

@media screen and (max-width: 991px) {
    .leftWrapper {
        margin: 0px;
        min-width: 0px;
        display: block;
        max-height: none;
        overflow: hidden;
    }

    .rightWrapper {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: grid;
        grid-gap: 0rem;
        grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
        background-color: var(--theme-ui-colors-bliss,#FFFFFF);
        grid-template-rows: 1fr;
        grid-row-gap: 0rem;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rightWrapperlv1 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        grid-column: start / end;
        display: flex;
        flex-direction: column;
        margin-top: 0rem;
        padding: 0rem;
    }

    .rightWrapperlv2 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: flex;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -webkit-box-pack: start;
        justify-content: flex-start;
        padding: 1rem;
    }

    .title {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        margin-bottom: 0.5rem;
    }

    .titleh2 {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        font-size: 1.25rem;
        line-height: 1.35;
        letter-spacing: normal;
        font-weight: 500;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid;
        border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    }

    .spanText {
        font-weight: 400;
        line-height: 1.5;
        box-sizing: border-box;
        font-size: 0.875rem;
        margin: 0;
        min-width: 0;
    }

    .radioWrapper {
        font-weight: 400;
        box-sizing: border-box;
        margin: 0px 0px 0.75rem;
        min-width: 0px;
        display: grid;
        gap: 1rem;
    }

    .facilityChoice {
        font-weight: 400;
        line-height: 1.5;
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: grid;
        gap: 0.75rem;
        width: 100%;
        grid-auto-flow: row;
        grid-template-columns: 1fr 1fr;
    }

    .rooflabelActive {
        padding: 0.75rem;
        font-size: 1rem;
    }

    .roofLabel {
        padding: 0.75rem;
        font-size: 1rem;
    }

    .formwizard {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        grid-column: 1 / -1;
        position: absolute;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 4px;
        bottom: 0rem;
        margin-top: auto;
        padding: 0rem;
    }

    .formwizardlv1 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        padding: 0.5rem 0.5rem;
    }

    .backButton {
        border-radius: 99999px;
        padding: 1rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
    }

    .nextButton {
        border-radius: 99999px;
        padding: 1rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
        margin-left: 0.25rem;
    }

    .backButtonIcon {
        font-size: 0;
        margin-right: 0rem;
    }

    .buttonIcon {
        font-size: 0;
        margin-left: 0rem;
    }

    .startText {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: none;
    }

    .adresGir {
        font-size: 0.875rem;
    }

    .spanRoofLabel {
        font-size: 0.875rem;
    }

    .optionsWrapper {
        font-weight: 400;
        line-height: 1.5;
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: grid;
        gap: 0.75rem;
        grid-auto-flow: row;
        -webkit-box-align: center;
        align-items: center;
    }

    .checkWrapper {
        font-weight: 400;
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-evenly;
    }

    .extraLabelActive {
        padding: 0.75rem;
    }

    .extraLabelDeactive {
        padding: 0.75rem;
    }

    .extraTexth3 {
        font-size: 0.875rem;
        margin-top: 0.6rem;
    }

    .battery {
        font-size: 0.875rem;
    }

    .buttonContainer {
        align-items: flex-start;
    }

    .costSavingText {
        font-size: 0.8rem;
    }

    .costSavingText2 {
        font-weight: 500;
    }
}