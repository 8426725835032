@import '../../index.css';

.outerWrapper {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-family: inherit;
    line-height: inherit;
    border: 0 solid;
    box-sizing: border-box;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-border-opacity: 1;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(0,133,214,0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    border-color: rgba(231,229,228,var(--tw-border-opacity));
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 40;
    --tw-bg-opacity: 1;
    background-color: rgba(255,255,255,var(--tw-bg-opacity));
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    --tw-shadow: 0px 4px 6px -2px rgba(0,0,0,0.05),0px 10px 15px -3px rgba(0,0,0,0.1);
    padding: 1.5rem;
}

.wrapper {
    width: 100%;
    max-width: 1536px;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;
}

.innerWrapper {
    display: grid;
    grid-template-columns: repeat(1,minmax(0,1fr));
    grid-template-rows: repeat(2,minmax(0,1fr));
    --tw-bg-opacity: 1;
    background-color: rgba(255,255,255,var(--tw-bg-opacity));
}

.stepper {
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    position: relative;
    display: flex;
    height: 1.25rem;
    justify-content: space-around;
    vertical-align: middle;
}

.step {
    align-items: center;
    position: relative;
    display: flex;
    width: 100%;
    vertical-align: middle;
    margin-left: 0;
}

.activeLine {
    margin-right: .125rem;
    display: block;
    width: 100%;
    border-radius: .75rem;
    --tw-bg-opacity: 1;
    background-color: var(--secondary-color);
    height: .5rem;
}

.passiveLine {
    margin-right: .125rem;
    display: block;
    width: 100%;
    border-radius: .75rem;
    --tw-bg-opacity: 1;
    background-color: rgba(232,232,232,var(--tw-bg-opacity));
    height: .5rem;
}

.submitLine {
    margin-right: .125rem;
    display: block;
    width: 100%;
    border-radius: .75rem;
    --tw-bg-opacity: 1;
    background-color: #7dbb18;
    height: .5rem;
}

.activePoint {
    cursor: pointer;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-text-opacity: 1;
    color: inherit;
    text-decoration: inherit;
    position: absolute;
    right: 0;
    z-index: 10;
    border-radius: .75rem;
    --tw-bg-opacity: 1;
    background-color: var(--secondary-color);
    height: 1.25rem;
    width: 1.25rem;
}

.passivePoint {
    cursor: pointer;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-text-opacity: 1;
    color: inherit;
    text-decoration: inherit;
    position: absolute;
    right: 0;
    z-index: 10;
    border-radius: .75rem;
    --tw-bg-opacity: 1;
    background-color: rgba(232,232,232,var(--tw-bg-opacity));
    height: 1.25rem;
    width: 1.25rem;
}

.submitPoint {
    cursor: pointer;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-text-opacity: 1;
    color: inherit;
    text-decoration: inherit;
    position: absolute;
    right: 0;
    z-index: 10;
    border-radius: .75rem;
    --tw-bg-opacity: 1;
    background-color: #7dbb18;
    height: 1.25rem;
    width: 1.25rem;
}

.buttonContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
}

.nextButton {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-text-opacity: 1;
    text-decoration: inherit;
    margin-top: 1rem;
    width: auto;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    border-radius: 0.3125rem;
    cursor: pointer;
    font-family: inherit;
    font-weight: 400;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0px 1rem;
    background-color: rgb(17, 47, 72);
    box-shadow: rgba(17, 47, 72, 0.24) 0px 4px 24px 0px;
    color: rgb(255, 255, 255);
}