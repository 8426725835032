.mainWrapper {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  tab-size: 4;
  --reach-skip-nav: 1;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.headerOne {
  margin: 0;
  min-width: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  font-size: 0.75rem;
  top: -0.5rem;
  left: -0.5rem;
  width: 1px;
  height: 1px;
}

.outerWrapper {
  box-sizing: border-box;
  margin: 0px 10vw;
  min-width: 0;
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  align-content: center;
  padding: 1rem 0;
}

.standartParagraph {
  margin: 15px 0px;
}

.justBoldParagraph {
  margin: 15px 0px;
  font-weight: bold;
}

.boldItalicUnderlineParagraph {
  margin: 15px 0px;
  font-weight: bold;
  text-decoration: underline;
  font-style: italic;
}

.boldItalicSpan {
  font-weight: bold;
  font-style: italic;
}

.boldItalicUnderlineSpan {
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

.justBoldSpan {
  font-weight: bold;
}