@import '../../index.css';

.SearchContainer {
    font-family: var(--font-fam);
    text-align: center;
    display: flex;
    z-index: 2;
    align-items: center;
    border: 1px solid #373242;
    border-radius: 35px;
    padding: 2px
  }

  .title {
    margin: 0 0 0.75rem;
    font-size: 2.25rem;
  }
  
  .subtitle {
    margin: 0 0 2.5rem;
    font-size: 1.25rem;
  }
  
  .logo {
    text-align: right;
    padding: 0.25rem 0.5rem;
  }

.inputContainer {
    width: 20vw; 
    font-size: 15px;
    padding: 0px;
    padding-left: 9px;
}


.iconContextCss {
  font-size: 30px;
  margin-top: 5px;
  margin-right: 10px;
}

@media screen and (max-width: 820px) {
  .inputContainer {
    width: 260px;
    font-size: 0.875rem; 
    padding: 0;
  }

  .iconContextCss {
    font-size: 27px;
    margin-top: 0;
  }

  .SearchContainer {
    margin-right: 10px;
  }

}
