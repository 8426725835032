.copyrightWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: fit-content;
    font-size: 14px;
    background-color: white;
    padding: 10px 0px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 16%);
}

@media screen and (max-width: 991px) {
    .copyrightWrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: fit-content;
        font-size: 10px;
        box-shadow: 0 4px 10px rgb(0 0 0 / 16%);
    }
}
