@import '../../index.css';

.leftWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    overflow: hidden;
    display: flex;
    max-height: none;
}

.rightWrapper {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
    background-color: var(--theme-ui-colors-bliss,#FFFFFF);
    grid-template-rows: 1fr;
    overflow-y: auto;
    overflow-x: hidden;
    grid-row-gap: 0rem;
}

.map {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
    background: url("/turkey_map.png") center / cover;
}

.rightWrapperlv1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    grid-column: start / end;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    padding: 0rem;
}

.rightWrapperlv2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 2rem;
}

.title {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-bottom: 1.125rem;
}

.nextButtonDeactive {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: not-allowed;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--theme-ui-colors-void,#BABABA);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    align-self: flex-start;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
    margin-top: 0rem;
    margin-left: 1rem;
}

.nextButtonActive {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--primary-color);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    align-self: flex-start;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
    margin-top: 0rem;
    margin-left: 1rem;
}

.startText {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.buttonIcon {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-left: 1rem;
}

.inputAdressWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
}

.titleh2 {
    margin: 0;
    min-width: 0;
    line-height: 1.35;
    letter-spacing: normal;
    font-weight: 500;
    border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    font-size: 1.5rem;
    padding-bottom: 0rem;
    margin-bottom: 0.75rem;
    border-bottom: none;
}

@media screen and (max-width: 991px) {
    .leftWrapper {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: block;
        max-height: 30vh;
        overflow: hidden;
    }

    .rightWrapper {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: grid;
        grid-gap: 0rem;
        grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
        background-color: var(--theme-ui-colors-bliss,#FFFFFF);
        grid-template-rows: 1fr;
        grid-row-gap: 0rem;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rightWrapperlv1 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        grid-column: start / end;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        padding: 0rem;
    }

    .rightWrapperlv2 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: flex;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -webkit-box-pack: start;
        justify-content: flex-start;
        padding: 1rem;
    }

    .title {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        margin-bottom: 1.125rem;
    }

    .inputAdressWrapper {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
    }

    .titleh2 {
        font-size: 1.25rem;
        line-height: 1.35;
        letter-spacing: normal;
        font-weight: 500;
        padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;
        border-bottom: 1px solid;
        border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    }

    .nextButtonDeactive {
        border-radius: 99999px;
        padding: 1rem 1.5rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
        margin-left: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        align-self: flex-start;
    }

    .nextButtonActive {
        border-radius: 99999px;
        padding: 1rem 1.5rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
        margin-left: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        align-self: flex-start;
    }

    .startText {
        line-height: 1.15;
        font-size: 0.875rem;
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: none
    }
    
    .buttonIcon {
        line-height: 1.15;
        font-size: 0.875rem;
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        margin-left: 0.2rem;
    }

    .adresGir {
        font-size: 0.875rem;
    }
}