@import '../../index.css';

.leftWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    overflow: hidden;
    max-height: none;
}

.rightWrapper {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
    background-color: var(--theme-ui-colors-bliss,#FFFFFF);
    grid-template-rows: 1fr;
    overflow-y: auto;
    overflow-x: hidden;
    grid-row-gap: 0rem;
}

.directionTextRegion {
    position: absolute;
    align-items: center;
    justify-content: center;
    justify-items: center;
    z-index: 1;
    font-size: 20px;
    width: 50%;
    border-radius: 30px;
    text-align: center;
    padding: 0px 30px;
}

.directionText {
    padding: 10px;
    font-size: 1rem;
    background-color: white;
    color: black;
    border-radius: 10px;
}

.map {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
    background: url("/home_photo.jpg") center / cover;
}

.rightWrapperlv1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    grid-column: start / end;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    padding: 0rem;
}

.rightWrapperlv2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 2rem;
}

.title {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-bottom: 1.125rem;
}

.titleh2 {
    margin: 0;
    min-width: 0;
    line-height: 1.35;
    letter-spacing: normal;
    font-weight: 500;
    border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    font-size: 1.5rem;
    padding-bottom: 0rem;
    margin-bottom: 0.75rem;
    border-bottom: none;
}


.formwizard {
    margin: 0px;
    min-width: 0px;
    grid-column: 1 / -1;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 4px;
    bottom: 0rem;
    margin-top: auto;
    position: sticky;
    background-color: white;
    padding: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.formwizardlv1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding: 1rem 0rem;
}

.buttonContainer {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.nextButton {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--primary-color);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    align-self: flex-start;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
    margin-top: 0rem;
    margin-left: 1rem;
}

.backButton {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--theme-ui-colors-gray-2,#BABABA);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
}

.startText {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.buttonIcon {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-left: 1rem;
}

.backButtonIcon {
    text-transform: none;
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    font-family: "Beausite Classic";
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    transform: scaleX(-1);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    margin-right: 1rem;
}

.inputAdressWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
}

.radioWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60%;
}

.radioDivider {
    display: flex;
    flex-direction: row;
}

.rooflabelActive {
    margin: 0px;
    min-width: 0px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--primary-color);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    cursor: pointer;
    transition: all 150ms ease 0s;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 1rem;
    padding: 1rem;
}

.roofLabel {
    margin: 0px;
    min-width: 0px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    background-color: whitesmoke;
    cursor: pointer;
    transition: all 150ms ease 0s;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 1rem;
    padding: 1rem;
}

.roofDiv {
    color: inherit;
    cursor: pointer;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-left: 0.75rem;
    flex-shrink: 0;
}

.spanRoofLabel {
    cursor: pointer;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.radioRoof {
    min-width: 0px;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    max-width: 100%;
    border: 1px solid;
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0px;
}

@media screen and (max-width: 991px) {
    .leftWrapper {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: block;
        max-height: 38vh;
        overflow: hidden;
    }

    .rightWrapper {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: grid;
        grid-gap: 0rem;
        grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
        background-color: var(--theme-ui-colors-bliss,#FFFFFF);
        grid-template-rows: 1fr;
        grid-row-gap: 0rem;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rightWrapperlv1 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        grid-column: start / end;
        display: flex;
        flex-direction: column;
        margin-top: 0rem;
        padding: 0rem;
    }

    .rightWrapperlv2 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: flex;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -webkit-box-pack: start;
        justify-content: flex-start;
        padding: 1rem;
    }

    .title {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        margin-bottom: 1.125rem;
    }

    .titleh2 {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        font-size: 1.25rem;
        line-height: 1.35;
        letter-spacing: normal;
        font-weight: 500;
        padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;
        border-bottom: 1px solid;
        border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    }

    .spanText {
        font-weight: 400;
        line-height: 1.5;
        box-sizing: border-box;
        font-size: 0.875rem;
        margin: 0;
        min-width: 0;
    }

    .radioWrapper {
        font-weight: 400;
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: grid;
        gap: 0.75rem;
        width: 100%;
        margin-bottom: 6rem;
    }

    .rooflabelActive {
        padding: 0.75rem 1.125rem;
        font-size: 0.8rem;
    }

    .roofLabel {
        padding: 0.75rem 1.125rem;
        font-size: 0.8rem;
    }

    .formwizard {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        grid-column: 1 / -1;
        position: absolute;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 4px;
        bottom: 0rem;
        margin-top: auto;
        padding: 0rem;
    }

    .formwizardlv1 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        padding: 0.5rem 1rem;
    }

    .backButton {
        border-radius: 99999px;
        padding: 1rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
    }

    .nextButton {
        border-radius: 99999px;
        padding: 1rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
    }

    .backButtonIcon {
        font-size: 0;
        margin-right: 0rem;
    }

    .buttonIcon {
        font-size: 0;
        margin-left: 0rem;
    }

    .startText {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: none;
    }

}