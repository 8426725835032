/* .popout:hover {
  animation: popout 1s ease;
  -webkit-animation: popout 1s ease;
}
@keyframes popout {
  from{transform:scale(1)}
  to{transform:scale(1.2)}
}
@-webkit-keyframes popout {
  from{-webkit-transform:scale(1)}
  to{-webkit-transform:scale(1.2)}
} */

.grow { 
  transition: all .2s ease-in-out; 
  }
  
.grow:hover { 
transform: scale(1.05); 
}