.headerWrapper {
    margin: 0px;
    min-width: 0px;
    height: 79px;
    width: 100%;
}

.mobileHeader {
    width: 100%;
    background-color: white;
    border-radius: 10px; 
}

.headroom {
    margin: 0px;
    min-width: 0px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transform: translate3D(0, 0, 0);
}

.section {
    margin: 0px;
    min-width: 0px;
    width: 100%;
    background-color: var(--theme-ui-colors-background,#FFFFFF);
    box-shadow: 0 4px 10px rgba(0,0,0,.16);
    display: flex;
}

.innerWrapper {
    min-width: 0;
    display: grid;
    grid-template-columns: 0 repeat(12,[start] 1fr) [end] 0;
    width: 100%;
    max-width: 81.25rem;
    margin-left: auto;
    margin-right: auto;
    grid-gap: 2rem;
}

.navigationSection {
    margin: 0px;
    min-width: 0px;
    grid-column: start / end;
    padding: 0rem;
}

.NavigationHeader {
    margin: 0;
    min-width: 0;
    position: relative;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
}

.hrefTag {
    color: inherit;
    text-decoration: underline transparent;
    transition: all 150ms ease 0s;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
    text-decoration-color: transparent;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 160px;
}

.logo {
    letter-spacing: normal;
    width: 100%;
}

@media screen and (max-width: 991px) {
    .header {
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizelegibility;
        tab-size: 4;
        --reach-skip-nav: 1;
        font-weight: 400;
        line-height: 1.5;
        color: var(--theme-ui-colors-void,#373242);
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
    }

    .headerWrapper {
        color: var(--theme-ui-colors-void,#373242);
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        height: 65px;
    }

    .innerWrapper {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 0 repeat(12,[start] 1fr) [end] 0;
        width: 100%;
        max-width: 81.25rem;
        margin-left: auto;
        margin-right: auto;
        row-gap: 1rem;
    }

    .seperateItems {
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        display: grid;
        grid-gap: 1rem;
        height: 65px;
        position: relative;
        grid-template-columns: auto auto;
        justify-content: center;
    }

    .navv {
        color: var(--theme-ui-colors-void,#373242);
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        width: 180px;
    }

    .buttonTextUnderline {
        box-sizing: border-box;
        min-width: 0px;
        font-family: inherit;
        line-height: 1.15;
        margin: 0;
        text-transform: none;
        position: relative;
        z-index: 0;
        display: inline-flex;
        appearance: none;
        border-top: none;
        border-right: none;
        border-left: none;
        border-image: initial;
        box-shadow: none;
        color: var(--theme-ui-colors-void,#373242);
        font-weight: 500;
        cursor: pointer;
        opacity: 1;
        pointer-events: auto;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-shrink: 0;
        vertical-align: top;
        white-space: nowrap;
        transition: all 150ms ease 0s;
        background-color: var(--theme-ui-colors-bliss,#FFFFFF);
        height: 100%;
        width: 20%;
        font-size: 1rem;
        border-radius: 0px;
        padding-top: 5px;
        border-bottom-width: 3px;
        border-bottom-style: solid;
        border-bottom-color: var(--theme-ui-colors-void,#373242);
    }

    .buttonText {
        box-sizing: border-box;
        min-width: 0px;
        font-family: inherit;
        line-height: 1.15;
        margin: 0;
        text-transform: none;
        position: relative;
        z-index: 0;
        display: inline-flex;
        appearance: none;
        border: none;
        box-shadow: none;
        color: var(--theme-ui-colors-void,#373242);
        font-weight: inherit;
        cursor: pointer;
        opacity: 1;
        pointer-events: auto;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-shrink: 0;
        vertical-align: top;
        white-space: nowrap;
        transition: all 150ms ease 0s;
        background-color: var(--theme-ui-colors-bliss,#FFFFFF);
        height: 100%;
        width: 20%;
        font-size: 1rem;
        border-radius: 0px;
    }

    .logo {
        letter-spacing: normal;
        width: 100%;
    }

    .hrefTag {
        color: inherit;
        text-decoration: underline transparent;
        transition: all 150ms ease 0s;
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        text-transform: none;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: normal;
        text-decoration-color: transparent;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 120px;
    }
}

