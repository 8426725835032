@import '../../index.css';

.leftWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: var(--theme-ui-colors-background,#FFFFFF);
    /* background-image: linear-gradient(106.61deg, rgb(101, 58, 148) 40.95%, rgb(212, 133, 165) 138.11%); */
    background-color: var(--secondary-color);
    background-position: 0% center;
    background-size: 200%;
    /* grid-template-rows: 1fr auto 2fr; */
    gap: 1rem;
    padding: 2.5rem;
}

.leftWrapperIstanbulGES {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: var(--theme-ui-colors-background,#FFFFFF);
    background-image: linear-gradient(106.61deg, #7a7a7a 40.95%, rgb(255 255 255) 138.11%);
    background-position: 0% center;
    background-size: 200%;
    /* grid-template-rows: 1fr auto 2fr; */
    gap: 1rem;
    padding: 2.5rem;
}

.projectInfoWrapper {
    color: var(--theme-ui-colors-background,#FFFFFF);
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: fit-content;
    width: 100%;
    border-radius: 10px;
    background-color: rgba(233, 233, 233, 0.3);
    padding: 1.5rem 2.5rem;
    margin-bottom: 0rem;
}

.projectInfo {
    color: var(--theme-ui-colors-background,#FFFFFF);
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;
    gap: 0.75rem;
    grid-template-columns: auto auto;
    font-size: 0.875rem;
}

.littleIcon {
    color: var(--theme-ui-colors-background,#FFFFFF);
    font-size: 0.875rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.summaryWrapper {
    color: var(--theme-ui-colors-background,#FFFFFF);
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: fit-content;
    margin-bottom: 0rem;
}

.summary1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    grid-column: start / end;
    padding: 0rem;
}

.summary2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    grid-column: start / end;
    height: fit-content;
    font-size: 1rem;
    padding: 0rem;
}

.summary2Comp1 {
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding-top: 0.75rem;
}

.summaryComp1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1rem;
}

.summaryCompSerh {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-weight: 400;
    font-size: 0.6rem;
    margin-top: 2rem;
}

.monthlyPrice {
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
}

.priceValue {
    font-weight: 500;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.priceSerh {
    font-weight: 400;
    font-size: 0.8rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.extraInfo {
    font-weight: 500;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    position: relative;
}

.outputPrice {
    font-weight: 500;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    align-self: flex-end;
}

.summaryComp2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-top: 0.75rem;
    border-bottom: 1px solid white;
    font-size: 1rem;
}

.rightWrapper {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    grid-template-columns: 0 repeat(1,[start] 1fr) [end] 0;
    background-color: var(--theme-ui-colors-bliss,#FFFFFF);
    grid-template-rows: 1fr;
    overflow-y: auto;
    overflow-x: hidden;
    grid-row-gap: 0rem;
}

.rightWrapperBefore {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 0rem;
    background-color: var(--theme-ui-colors-bliss,#FFFFFF);
    grid-template-rows: 1fr;
    overflow-y: auto;
    overflow-x: hidden;
    grid-row-gap: 0rem;
    grid-column: 1 / 3;
}

.map {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 100%;
    width: 100%;
    background: url("/harita_ss.PNG") center / cover;
}

.rightWrapperlv1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    grid-column: start / end;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    padding: 0rem;
}

.rightWrapperlv1Before {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    padding: 0rem;
    place-items: center;
    background-color: var(--secondary-color);
    background-position: 0% center;
    justify-content: center;
}

.rightWrapperlv1BeforeIstanbulGES {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    padding: 0rem;
    place-items: center;
    background-image: linear-gradient(106.61deg, #aeaeae 40.95%, rgb(255 255 255) 138.11%);
    background-position: 0% center;
    justify-content: center;
}

.rightWrapperlv2 {
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 2rem;
}

.rightWrapperlv2Before {
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-box-pack: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    background-color: white;
    padding: 3rem;
    border-radius: 1rem;
}

.title {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-bottom: 1.125rem;
}

.graphWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-bottom: 1.125rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}

.formwizard {
    margin: 0px;
    min-width: 0px;
    grid-column: 1 / -1;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 4px;
    bottom: 0rem;
    margin-top: auto;
    position: sticky;
    background-color: white;
    padding: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.formwizardUpdate {
    margin: 0px;
    min-width: 0px;
    width: 100%;
    background-color: white;
    padding: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
}

.formwizardlv1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding: 1rem 0rem;
}

.buttonContainer {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.nextButton {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    /* background-color: var(--theme-ui-colors-void,#373242); */
    background-color: var(--secondary-color);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    align-self: flex-start;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
    margin-top: 0rem;
    margin-left: 1rem;
}

.nextButton:disabled {
    background-color: var(--theme-ui-colors-void,#BABABA);
    background-image: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    cursor: not-allowed;
}

.backButton {
    box-sizing: border-box;
    min-width: 0px;
    margin: 0;
    text-transform: none;
    position: relative;
    z-index: 0;
    display: inline-flex;
    appearance: none;
    border: none;
    box-shadow: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    white-space: nowrap;
    transition: all 150ms ease 0s;
    background-color: var(--theme-ui-colors-gray-2,#BABABA);
    border-radius: 99999px;
    padding: 1rem;
    line-height: 1.15;
    min-height: 3rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    font-size: 1rem;
}

.startText {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
}

.finishText {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;    
}

.buttonIcon {
    text-transform: none;
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    margin-left: 1rem;
}

.backButtonIcon {
    text-transform: none;
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    white-space: nowrap;
    font-family: "Beausite Classic";
    line-height: 1.15;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    transform: scaleX(-1);
    color: var(--theme-ui-colors-bliss,#FFFFFF);
    margin-right: 1rem;
}

.inputAdressWrapper {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
}

.titleh2 {
    margin: 0;
    min-width: 0;
    line-height: 1.35;
    letter-spacing: normal;
    font-weight: 500;
    border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    font-size: 1.5rem;
    padding-bottom: 0rem;
    margin-bottom: 0.75rem;
    border-bottom: none;
}

.inputContainer {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;
    gap: 0.5rem;
    padding-left: 0rem;
    list-style: none;
    grid-auto-flow: row;
    grid-template-columns: auto;
    align-content: start;
    font-size: 1rem;
}

.inputs {
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;
    gap: 0.75rem 0rem;
    padding-left: 0rem;
    list-style: none;
    grid-auto-flow: row;
    align-content: start;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.inputsEmailOnly {
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
}

.input {
    font-size: 1rem;
    list-style: none;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
}

.checkboxWrapper {
    list-style: none;
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    position: relative;
}

.checkbox {
    list-style: none;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: inline-flex;
    font-size: 0.75rem;
    -webkit-box-align: center;
    align-items: center;
}

.checkboxLabel {
    list-style: none;
    font-size: 0.75rem;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: inline-flex;
}

@media screen and (max-width: 991px) {
    .leftWrapper {
        grid-template-rows: auto;
        gap: 0.75rem;
        padding: 1.125rem;
        color: var(--theme-ui-colors-background,#FFFFFF);
        background-position: 0% center;
        background-size: 200%;
    }

    .projectInfoWrapper {
        padding: 0.5rem;
        margin-bottom: 1.125rem;
    }

    .rightWrapper {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        position: relative;
        z-index: 0;
        overflow: visible;
        background-color: var(--theme-ui-colors-background,#FFFFFF);
    }

    .rightWrapperlv1 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        height: 100%;
        overflow: auto;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .rightWrapperlv2 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        margin-top: 1rem;
        padding: 0;
    }

    .titleh2 {
        font-size: 1.25rem;
        line-height: 1.35;
        letter-spacing: normal;
        font-weight: 500;
        padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;
        border-bottom: 1px solid;
        border-color: var(--theme-ui-colors-concrete,#C4C4C4);
    }

    .infoText {
        font-weight: 300;
        font-size: 0.875rem;
    }

    .inputs .inputsEmailOnly {
        display: grid;
        gap: 0.75rem 0rem;
        padding-left: 0rem;
        list-style: none;
        grid-auto-flow: row;
        grid-template-columns: unset;
        align-content: start;
    }

    .buttonContainer {
        padding-top: 10px;
    }

    /* .formwizard {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        margin-top: auto;
        overflow: hidden;
        padding-top: 0.75rem;
    } */

    .formwizard {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        grid-column: 1 / -1;
        position: relative;
        background-color: var(--theme-ui-colors-background,#FFFFFF);
        width: 100%;
        box-shadow: none;
        bottom: 0rem;
        margin-top: auto;
        padding: 0rem;
    }

    .formwizardlv1 {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        padding: 0.5rem 0rem;
    }

    .backButton {
        border-radius: 99999px;
        padding: 1rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
    }

    .nextButton {
        border-radius: 99999px;
        padding: 1rem;
        line-height: 1.15;
        min-height: 2.625rem;
        font-size: 0.875rem;
    }

    .backButtonIcon {
        font-size: 0;
        margin-right: 0rem;
    }

    .buttonIcon {
        font-size: 0;
        margin-left: 0rem;
    }

    .startText {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: none;
    }

}
