.pageWrapper {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: inherit;
}

.loginWrapper {
    width: 40%;
    align-items: center;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.iconWrapper {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.solarImage {
    width: 100%;
    object-fit: cover;
    align-items: center;
    height: 100%;
}

@media screen and (max-width: 991px) {
    .loginWrapper {
        width: 100%;
        padding: 20px;
        padding-top: 10px;
    }

    .container {
        background-image: url('../../assets/resources/solarpanel.png');
        background-size: cover;
        background-position: center;
    }

    .form {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
    }
}