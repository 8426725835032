@import './index.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--font-fam);
}

html {
  scroll-behavior: smooth;
}